.home_image1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}

.home_image2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.home_heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  margin-bottom: 1rem;
  color: var(--gray);
  padding: 12px;
}

.home_subheading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 64px;
  font-weight: 400;
  font-style: normal;
  line-height: 80px;
  margin-bottom: 24px;
  color: var(--white);
  white-space: pre-line;
  padding: 12px;
}

.background-blue {
  background-image: url('../../assets/blue-background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

/* TODO: fix image position on mobile */
@media only screen and (max-width: 600px) {
  .home_image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -2;
  }

  .home_image2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .home_mobile_background {
    background-image: url('../../assets/homepage-hero-bg.webp');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    background-color: transparent;
    position: relative;
    top: 0;
  }

  .home_heading {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-bottom: 1rem;
    color: var(--gray);
    padding: 12px;
  }

  .home_subheading {
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
    line-height: 130%;
    margin-bottom: 24px;
    color: var(--white);
    white-space: pre-line;
    padding: 0 12px;
  }
}
