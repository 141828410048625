.services_container {
  display: flex;
  padding-top: 2rem;
}

.services_grid {
  display: grid;
  align-items: center;
  justify-content: center;
}

.services_subheading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #0076ff;
}

.services_heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
  line-height: 60px;
  text-align: center;
  color: var(--white);
  white-space: pre-line;
}

.services_divider {
  background-color: var(--blue);
  height: 2px;
  width: 64px;
  margin-left: auto;
  margin-right: auto;
}

.services_items {
  width: 60% !important;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.home_services_image {
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.services_items_heading {
  color: var(--white);
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  margin-left: 1.5rem !important;
  margin-right: 1rem !important;
}

.services_items_text {
  margin-top: 0.5rem !important;
  margin-bottom: 56px !important;
  color: var(--gray);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  line-break: auto;
  max-width: 35ch;
  margin-left: 1.5rem !important;
  margin-right: 1rem !important;
}

.home_wrapper .services_heading {
  font-size: 48px;
  line-height: 60px;
}

@media only screen and (max-width: 600px) {
  .about_container {
    display: grid;
    justify-content: center;
  }

  .services_grid {
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: -14rem !important;
  }

  .services_subheading {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #0076ff;
  }
  .home_wrapper .services_heading {
    font-size: 28px !important;
  }
  .services_heading {
    font-size: 28px;
    font-weight: 400;
    font-style: normal;
    line-height: 60px;
    text-align: center;
    color: var(--white);
    white-space: pre-line;
  }

  .services_divider {
    margin-bottom: 1rem;
    background-color: var(--blue);
    height: 2px;
    width: 64px;
    margin-left: auto;
    margin-right: auto;
  }

  .services_items {
    width: 100% !important;
    padding: 0 1rem;
    margin-top: -2rem;
    justify-content: center;
    display: flex;
    text-align: center;
  }

  .services_items_heading {
    color: var(--white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .services_items_text {
    margin-top: 1rem;
    color: var(--gray);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    line-break: normal;
  }

  .services_texts {
    text-align: block;
  }

  .home_services_image {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    text-align: center;
  }

  .services_button {
    padding-top: 1rem;
  }
}
