.details_container {
  align-items: center;
  display: flex;
  text-align: center;
  margin-top: 12rem;
}

.details_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}

.details_heading {
  font-size: 64px;
  font-weight: 400;
  line-height: 80px;
  font-style: normal;
  margin-bottom: 24px;
  color: var(--white);
  white-space: pre-line;
  padding: 12px;
}

.details_subheading {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  font-style: normal;
  margin-bottom: 1rem;
  color: var(--gray);
  white-space: pre-line;
  padding: 12px;
}

.details_grid_container {
  text-align: center;
  padding: 4rem 2rem;
  align-items: center;
  display: flex;
  margin-top: 10rem;
  background-color: #0f0f0f;
}

.details_grid_item {
  display: grid;
  flex-direction: column;
  align-items: center;
}

.details_content_heading {
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  font-style: normal;
  color: var(--white);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.details_content_divider {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: var(--blue);
  margin-bottom: 1rem;
  height: 2px;
  width: 64px;
}

.details_content_subheading {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  color: var(--gray);
  padding: 12px;
  white-space: pre-line;
}

.details_idea_container {
  text-align: center;
  padding: 1rem 4rem;
  align-items: center;
  display: flex;
  background-color: #000;
}

@media only screen and (max-width: 600px) {
  .details_container {
    align-items: center;
    display: flex;
    height: 75vh;
    text-align: center;
    margin-top: 4rem;
  }

  .details_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -2;
  }

  .details_heading {
    font-size: 40px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 130%;
    margin-bottom: 24px;
    color: var(--white);
    white-space: pre-line;
    padding: 12px;
  }

  .details_subheading {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    font-style: normal;
    margin-bottom: 1rem;
    color: var(--gray);
    white-space: pre-line;
    padding: 12px;
  }

  .details_grid_container {
    text-align: start;
    padding: 0 1rem;
    align-items: center;
    display: flex;
    padding-top: 2rem;
    background-color: #0f0f0f;
    width: 100%; /* Ensuring it takes full width */
  }

  .details_grid_item {
    display: grid;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensuring it takes full width */
  }

  .details_content_heading {
    font-size: 32px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 110%;
    color: var(--white);
    text-align: left; /* Changed from center to left */
  }

  .details_content_divider {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: var(--blue);
    margin-bottom: 1rem;
    height: 2px;
    width: 64px;
  }

  .details_content_subheading {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    line-height: 24px;
    color: var(--white);
    text-align: left;
  }

  .details_idea_container {
    text-align: center;
    padding: 1rem 4rem;
    align-items: center;
    display: flex;
    background-color: #000;
  }
}
