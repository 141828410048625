.work_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.work_grid {
  display: grid;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
}

.work_subheading {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: var(--blue);
}

.work_heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 64px;
  font-weight: 400;
  font-style: normal;
  line-height: 80px;
  text-align: center;
  color: var(--white);
  white-space: pre-line;
}

.work_text {
  position: relative;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  color: var(--gray);
  line-break: auto;
  max-width: 50ch;
  margin-top: 1.5rem;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .work_grid {
    display: grid;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .work_heading {
    font-size: 40px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 130%;
    text-align: center;
    color: var(--white);
    white-space: pre-line;
  }
  .work_text {
    padding: 0 2rem;
  }
}
