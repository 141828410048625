@font-face {
  font-family: 'Axiforma';
  src: url('../public/fonts/Axiforma-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --white: #ffffff;
  --gray: #aaaaaa;
  --blue: #0076ff;
}

/* h1, h2, h3 {
  margin-top: 0px;
}

h1, h2, h3, h4 {
  margin-bottom: 0px;
} */

.custom_outline {
  border-color: white !important;
}

.error_outline {
  border-color: red !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
