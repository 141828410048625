.services_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.services_grid {
  display: grid;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.services_subheading {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: var(--blue);
}

.services_heading {
  padding: 1rem 0 1rem 0;
  font-size: 64px;
  font-weight: 400;
  font-style: normal;
  line-height: 80px;
  text-align: center;
  color: var(--white);
  white-space: pre-line;
}

.services_text {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  color: var(--gray);
  line-break: auto;
  max-width: 50ch;
}

.services_row {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}

.services_row_item {
  cursor: pointer;
  margin: 0 10px;
  border-radius: 40px;
  padding: 12px 24px;
  background-color: rgba(255, 255, 255, 0.08);
  color: var(--gray);
}

@media only screen and (max-width: 600px) {
  .services_row {
    display: grid;
    margin-top: 8rem;
  }

  .services_row_item {
    margin: 10px 10px;
    border-radius: 40px;
    padding: 12px 24px;
    background-color: rgba(255, 255, 255, 0.08);
    color: var(--gray);
  }

  .services_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  .services_grid {
    display: grid;
    align-items: center;
    padding: 6rem 2rem 4rem 2rem;
    margin-top: -4rem !important;
  }

  .services_subheading {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: var(--blue);
  }

  .services_heading {
    padding: 1rem 0 1rem 0;
    font-size: 40px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 130%;
    text-align: center;
    color: var(--white);
    white-space: pre-line;
  }

  .services_text {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    line-height: 24px;
    text-align: center;
    color: var(--gray);
    line-break: auto;
    max-width: 50ch;
  }
}
