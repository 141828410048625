.ourwork_container {
  justify-content: start;
}

.ourwork_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ourwork_heading {
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--white);
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
}

.ourwork_blue_line {
  margin-top: 1rem;
  background-color: var(--blue);
  height: 2px;
  width: 64px;
}

.ourwork_text {
  color: var(--gray);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  line-break: auto;
  width: 59%;
}

.ourwork_wrapper {
  overflow: hidden;
}

.ourwork_card {
  display: flex;
  flex-direction: column;
  min-height: 25rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.08);
  background-image: url('../../assets/home_blue_small_light.png');
  background-size: cover;
  /* margin-left: 2rem; */
  height: auto;
}

.home_wrapper .slick-track {
  display: flex;
}

.home_wrapper .slick-track .slick-slide {
  display: flex;
  height: auto;
}

.slick-list {
  overflow: visible !important;
}

.slick-slider .ourwork_card {
  margin-left: 2rem;
}

.slick-slider .ourwork_card.first_element_slider {
  margin-left: 0
}

.ourwork_card2 {
  height: 85%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.08);
  background-image: url('../../assets/home_green_light.webp');
  background-size: cover;
  margin-left: 1rem;
}

.ourwork_card_heading {
  margin-bottom: 0px;
  margin-top: 3rem;
  color: var(--white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: pre-line;
}

.ourwork_card_text {
  color: var(--gray);
  margin-top: 1rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  line-break: auto;
}

.ourwork_card_button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 0 0;
  margin-top: 1rem;
  align-items: flex-end;
}

.ourwork_card_button_icon {
  padding: 6px;
  background-color: #0076ff;
  border-radius: 100px;
  margin-right: 1rem;
}

.home_wrapper .ourwork_heading {
  font-size: 48px;
  line-height: 60px;
}

@media only screen and (max-width: 600px) {
  .ourwork_container {
    justify-content: center;
    margin-top: 4rem;
  }

  .ourwork_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
  }

  .ourwork_heading {
    text-align: center;
    color: var(--white);
    font-size: 28px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  .ourwork_blue_line {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    background-color: var(--blue);
    height: 2px;
    width: 64px;
  }

  .ourwork_text {
    text-align: center;
    color: var(--gray);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin-top: 1rem;
    line-break: auto;
    max-width: 50ch;
    margin-left: auto;
    margin-right: auto;
  }

  .ourwork_card {
    margin-left: -1rem;
    margin-right: -1rem
  }

  /* .ourwork_card {
    width: 95%;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.08);
    background-image: url('../../assets/home_blue_small_light.png');
    background-size: cover;
    margin-left: -1.5rem;
  } */

  .ourwork_card2 {
    width: 95%;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.08);
    background-image: url('../../assets/home_green_light.webp');
    background-size: cover;
    margin-left: -1.5rem;
  }

  .ourwork_card_heading {
    margin-top: 2rem;
    color: var(--white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    white-space: pre-line;
  }

  .ourwork_card_text_mobile {
    margin-top: 1rem;
    color: var(--gray);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    white-space: pre-line;
  }

  .ourwork_card_button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 0;
    margin-top: 4rem;
  }

  .ourwork_card_button_icon {
    padding: 6px;
    background-color: #0076ff;
    border-radius: 100px;
    margin-right: 1rem;
  }
}
