.about_container {
  margin-top: 6rem;
  padding: 2rem 0rem !important;
}

.home_about_container {
  margin-top: 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_grid {
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
}

.about_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}

.about_subheading {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #0076ff;
}

.about_heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
  line-height: 60px;
  text-align: center;
  color: #fff;
  white-space: pre-line;
}

.about_divider {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  background-color: #0076ff;
  height: 2px;
  width: 64px;
}

.about_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-top: 2rem;
  text-align: center;
  color: var(--gray);
  line-break: auto;
  max-width: 50ch;
  margin-left: auto;
  margin-right: auto;
}

.home_wrapper .about_heading {
  font-size: 48px;
  line-height: 60px;
}

.about_button_div {
  justify-content: center;
  display: flex;
}

.home_about .about_heading, .home_about .about_subheading, .home_about .about_divider, .home_about .about_text, .home_about .about_button_div { 
  text-align: left;
}

.home_about .about_divider, .home_about .about_text, .home_about .about_button_div {
  margin-left: 0;
  justify-content: flex-start;
  max-width: unset;
}

.about_statistics {
  background-color: #969696;
  padding: 2rem 0;
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.about_statistics_heading {
  font-size: 3.5rem;
  line-height: 3.5rem
}

.about_statistics_text {
  font-size: 1.25rem;
  line-height: 1.25rem
}

@media only screen and (max-width: 600px) {
  .about_container {
    display: flex;
    justify-content: center;
    padding: 4rem;
  }

  .about_grid {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    padding: 0 2rem;
  }

  .about_image {
    display: none;
  }

  .about_subheading {
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #0076ff;
  }

  .home_wrapper .about_heading {
    font-size: 28px !important;
  }

  .about_heading {
    font-size: 28px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 130% !important;
    text-align: center;
    color: #fff;
    white-space: pre-line;
  }

  .about_divider {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    background-color: #0076ff;
    height: 2px;
    width: 64px;
  }

  .about_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin-top: 2rem;
    text-align: center;
    color: var(--gray);
    line-break: auto;
    max-width: 40ch;
  }
}