.project_container {
  padding: 6rem;
  margin-top: 3rem;
}

.project_container .ourwork_card {
  margin: 0
}

.project_background {
  height: 85%;
  padding: 2rem;
  margin: 2rem 0;
  background-color: rgba(255, 255, 255, 0.08);
  background-size: cover;
}
.project_heading {
  margin-top: 3rem;
  color: var(--white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.project_subheading {
  color: var(--gray);
  margin-top: 1rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  line-break: auto;
}
.project_button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4rem;
}

.project_icon {
  padding: 6px;
  background-color: var(--blue);
  border-radius: 100px;
  margin-right: 1rem;
}

@media only screen and (max-width: 600px) {
  .project_container {
    padding: 4rem 2rem;
    margin-top: 3rem;
  }

  .project_background {
    height: 85%;
    padding: 2rem;
    margin: 2rem 0;
    background-color: rgba(255, 255, 255, 0.08);
    background-size: cover;
  }
  .project_heading {
    margin-top: 3rem;
    color: var(--white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .project_subheading {
    color: var(--gray);
    margin-top: 1rem;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    line-break: auto;
    max-width: 45ch;
  }
  .project_button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4rem;
  }

  .project_icon {
    padding: 6px;
    background-color: var(--blue);
    border-radius: 100px;
    margin-right: 1rem;
  }
}
