.contact_container {
  display: flex;
  justify-content: center;
  padding: 2rem 6rem;
}

.contact_grid {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.contact_mail_address {
  color: var(--white);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact_icon {
  color: var(--blue);
  vertical-align: middle;
  margin-right: 1rem;
  font-size: 2rem !important;
}

.contact_grid2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.contact_line_div {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.contact_line {
  width: 90%;
  height: 1px;
  background-color: var(--white);
  opacity: 0.24;
}

@media only screen and (max-width: 600px) {
  .contact_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    padding: 1rem 1rem;
  }

  .contact_mail_address {
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    line-height: 1.5;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem; /* Add margin to separate from the button */
  }

  .contact_icon {
    color: var(--blue);
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 0.5rem; /* Add some spacing between icon and text */
  }

  .contact_grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact_line_div {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .contact_line {
    display: none;
  }
}
