body {
  font-family: 'Axiforma';
  margin: 0;
  padding: 0;
  background-color: #080808;
  color: white;
}

.badge {
  background-color: #00000080;
  padding: 0.5rem 1rem;
  border-radius: 100px;
  font-size: 0.75rem;
}

.section_heading_top { 
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: var(--blue);
}

.section_heading {
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
  line-height: 60px;
  text-align: left;
  color: var(--white);
  white-space: pre-line;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .section_heading {
    font-size: 2rem;
    line-height: normal;
    margin-bottom: 0.5rem;
  }
}

.section_heading_divider { 
  background-color: #0076ff;
  height: 2px;
  width: 64px;
}

.section_text {
  font-size: 16px;
  font-weight: 400;
  margin-top: 3rem;
  font-style: normal;
  line-height: 24px;
  text-align: left;
  color: #aaaaaa;
  line-break: auto;
}