.pricing_container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #080808;
  padding: 2rem 0;
}

@media only screen and (max-width: 600px) {
  .pricing_container {
    padding: 0 2rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
