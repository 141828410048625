.icons_container {
  display: flex;
  justify-content: center;
  padding: 2rem 6rem;
}

.icons_grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.icons_icon {
  margin: 0;
  opacity: 0.35; /* Set initial opacity */
  transition: opacity 0.3s ease; /* Add a smooth transition */
}

.icons_icon:hover {
  opacity: 1; /* Change opacity on hover */
  cursor: pointer;
}
