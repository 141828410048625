@media only screen and (max-width: 600px) {
  .singularity_startup_mobile_grid {
    position: relative;
    background-image: url('../../assets/startup-homepage-hero-bg.webp');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    background-color: transparent;
  }
}
