.startup_container {
  display: flex;
  justify-content: center;
  height: 90vh;
  position: relative;
}

.startup_image {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.startup_grid {
  text-align: center;
  z-index: 1;
  width: 100%;
}

.startup_subheading {
  font-size: 20px;
  color: var(--blue);
}

.startup_heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 48px;
  color: var(--white);
}

.startup_divider {
  margin: 1rem auto;
  background-color: var(--blue);
  height: 2px;
  width: 64px;
}

.startup_text {
  font-size: 16px;
  color: var(--gray);
  max-width: 50ch;
  margin: 0 auto;
}

.startup_button {
  margin: 2rem auto;
  padding: 20px 24px;
  color: #fff;
  border-radius: 0px;
}

.home_wrapper .startup_container {
  height: 800px;
}

@media only screen and (max-width: 600px) {
  .startup_container {
    display: flex;
    height: 80vh;
    padding: 2rem;
    position: relative;
  }

  .home_wrapper .startup_container {
    height: auto;
  }

  .startup_mobile_section {
    margin-top: -8rem;
  }

  .startup_grid {
    text-align: center;
    z-index: 1;
    width: 100%;
  }

  .startup_subheading {
    font-size: 20px;
    color: var(--blue);
  }

  .startup_heading {
    font-size: 32px;
    line-height: 38px;
    color: var(--white);
  }

  .startup_divider {
    margin: 1rem auto;
    background-color: var(--blue);
    height: 2px;
    width: 64px;
  }

  .startup_text {
    font-size: 16px;
    color: var(--gray);
    max-width: 50ch;
    margin: 0 auto;
  }

  .startup_button {
    margin: 2rem auto;
    padding: 20px 24px;
    color: #fff;
    border-radius: 0px;
  }
}
