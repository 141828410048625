.about_container {
  padding: 4rem 2rem 0 2rem;
}

.about_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.about_grid {
  display: grid;
  align-items: center;
  padding-top: 6rem;
}

.about_subheading {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: var(--blue);
}

.about_heading {
  font-size: 64px;
  font-weight: 400;
  font-style: normal;
  line-height: 80px;
  text-align: center;
  color: var(--white);
  white-space: pre-line;
}

.about_text {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 24px;
  text-align: center;
  color: #aaaaaa;
  line-break: auto;
  max-width: 800px;
}

@media only screen and (max-width: 600px) {
  .about_image_mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  .about_grid {
    display: grid;
    align-items: center;
    padding-top: 0;
  }
  .about_heading {
    font-size: 40px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 130%;
    text-align: center;
    color: var(--white);
    white-space: pre-line;
  }
}
